import React from "react";
import cookie from "react-cookies";
import { TabList, TabPanel, Tabs, Tab } from "react-tabs";
import { ToastContainer } from "react-toastify";
import NetworkSwitch from "./microcomponents/networkSwitch";
import Business from "./tabs/business";
import Personal from "./tabs/personal";
import "react-tabs/style/react-tabs.css";
import Security from "./tabs/security";
import Contact from "./tabs/contact";
import Network from "./tabs/network";

class Compliance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  onSave(event) {
    event.preventDefault();
    this.setState({
      networks: this.state.networks,
    });
  }

  handleChange(newValue) {
    this.setState({
      activeTab: newValue,
    });
  }

  moveToNext() {
    this.setState({
      activeTab: this.state.activeTab + 1,
    });
  }

  moveToPrevious() {
    if (this.state.activeTab > 0) {
      this.setState({
        activeTab: this.state.activeTab - 1,
      });
    }
  }

  componentDidMount() { }

  render() {
    return (
      <main className="page-content">
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <div className="card">
          <div className="card-body">
            <Tabs
              onSelect={this.handleChange}
              selectedIndex={this.state.activeTab}
            >
              <TabList>
                <Tab label="Profile">Profile</Tab>
                <Tab label="Business Info >">Business Info</Tab>
                <Tab label="Contacts >"> Contacts </Tab>
                <Tab label="Network and Security>">Network and Security</Tab>
              </TabList>

              <TabPanel index={0}>
                <Personal
                  moveToNext={this.moveToNext.bind(this)}
                  moveToPrevious={this.moveToPrevious.bind(this)}
                ></Personal>
              </TabPanel>
              <TabPanel index={1}>
                <Business
                  moveToNext={this.moveToNext.bind(this)}
                  moveToPrevious={this.moveToPrevious.bind(this)}
                ></Business>
              </TabPanel>
              <TabPanel index={2}>
                <Contact
                  moveToNext={this.moveToNext.bind(this)}
                  moveToPrevious={this.moveToPrevious.bind(this)}
                ></Contact>
              </TabPanel>
              <TabPanel index={3}>
                <Network
                  moveToNext={this.moveToNext.bind(this)}
                  moveToPrevious={this.moveToPrevious.bind(this)}
                  {...this.props}
                ></Network>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </main>
    );
  }
}

export default Compliance;
