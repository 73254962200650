import React from "react";
import Header from "./header";
import Main from "./main";
import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
  Navigate,
} from "react-router-dom";
import SideBar from "./SideBar";
import Transaction from "./transaction";
import Profile from "./profile";
import Users from "./users";
import Fund from "./fund";
import Support from "./support";
import WalletHistory from "./WalletHistory";
import TransferFund from "./transferFund";
import Vouchers from "./vouchers";
import GenerateVoucher from "./generateVoucher";
import CreateUser from "./CreateUser";
import cookie from "react-cookies";
import InviteAgent from "./InviteAgent";
import FundWalletReq from "./fundWalletReq";
import Uploaded from "./Uploaded-files";
import PrintUnits from "./PrintUnits";
import Footer from "./footer";
import Printed from "./PrintedFiles";
import GenerateVoucherB from "./generateVoucher_b";
import VouchersB from "./vouchers_B";
import VouchersHistory from "./vouchers_history";
import ReprintRequests from "./ReprintRequests";
import EditTransactionPin from "./EditTransactionPin";
import axios from "axios";
import { UNIFIED_BASE_URL, WEB_BASE_URL } from "./webbase";
import Compliance from "./compliance";
import FundWalletReqHistory from "./fundWalletReqHistory";
import UploadHistory from "./UploadHistory";
import PrintInvitations from "./PrintInvitations";
import Reports from "./reports";
import FundOperator from "./fundOperator";
import SignIn from "./signIn";
import queryString from "query-string";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.props = props;
    const query = queryString.parse(this.props?.location?.search);
    this.state = {
      existingToken: query?.token?.replace(/ /g, "+"),
      walletBalance: cookie.load("walletBalance"),
      token: cookie.load("token"),
      user: cookie.load("user"),
    };

    this.getWalletBalance = this.getWalletBalance.bind(this);
    this.getProfile = this.getProfile.bind(this);
  }

  getProfile() {
    axios
      .get(UNIFIED_BASE_URL + "/onboarding/get-user", {
        headers: {
          authorization: this.state.existingToken,
        },
      })
      .then((res) => {
        if (res.data.status == 2000) {
          console.log(res.data);
          this.setState({
            user: res.data.data[0],
          });
          cookie.save("token", this.state.existingToken, {
            path: "/",
            expires: new Date(Date.now() + 60 * 27 * 1000),
          });
          cookie.save("user", res.data.data[0], {
            path: "/",
            expires: new Date(Date.now() + 60 * 27 * 1000),
          });
          cookie.save("dealerId", res.data.data[0].dealerId, {
            path: "/",
            expires: new Date(Date.now() + 60 * 27 * 1000),
          });
          cookie.save("userName", res.data.data[0].firstName + res.data.data[0].lastName, {
            path: "/",
            expires: new Date(Date.now() + 60 * 27 * 1000),
          });
          cookie.save("userRole", res.data.data[0].role, {
            path: "/",
            expires: new Date(Date.now() + 60 * 27 * 1000),
          });
          this.setState({
            token: this.state.existingToken,
          });
        }
        else {
          console.log(res.data);
          console.log("Token is inaccurate");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getWalletBalance() {
    axios
      .get(UNIFIED_BASE_URL + "/onboarding/account-balance", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        if (res.data.status === "2000") {
          cookie.save("walletBalance", res.data.data.accountBalance, {
            path: "/",
            expires: new Date(Date.now() + 60 * 30 * 1000),
          });
        }
        else {
          console.log(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidMount() {
    const user = cookie.load("user");
    const token = cookie.load("token");
    const sidebar = cookie.load("sidebar");

    if (this.state.existingToken) {
      this.getProfile();
    }

    if (!user || !token || !sidebar) {
      this.setState({
        user: undefined,
      });
      return;
    } else {
      this.getWalletBalance();
    }
  }

  componentDidUpdate() {
    const user = cookie.load("user");
    const token = cookie.load("token");
    const sidebar = cookie.load("sidebar");

    if (this.state.user !== undefined) {
      if (!user || !token || !sidebar) {
        this.setState({
          user: undefined,
        });
      }
    }
  }

  render() {
    return this.state.token === undefined ? (
      <SignIn {...this.props} />
    ) : (
      <div>
        <Header></Header >
        <SideBar {...this.props}></SideBar>
        <Switch>
          <Route
            exact
            path="/"
            key={1}
            element={<Main {...this.props}></Main>}
          />
          <Route
            exact
            key={2}
            path="/transactions"
            element={<Transaction {...this.props}></Transaction>}
          />
          <Route
            exact
            key={3}
            path="/profile"
            element={<Profile {...this.props}></Profile>}
          />
          <Route
            exact
            key={4}
            path="/users"
            element={<Users {...this.props}></Users>}
          />
          <Route
            exact
            key={9}
            path="/invite-agents"
            element={<InviteAgent {...this.props}></InviteAgent>}
          />
          <Route
            exact
            key={5}
            path="/fund"
            element={<Fund {...this.props}></Fund>}
          />
          <Route
            exact
            key={24}
            path="/fund-operator"
            element={<FundOperator {...this.props}></FundOperator>}
          />
          <Route
            exact
            key={21}
            path="/fund-wallet-req"
            element={<FundWalletReq {...this.props}></FundWalletReq>}
          />
          <Route
            exact
            key={6}
            path="/support"
            element={<Support {...this.props}></Support>}
          />
          <Route
            exact
            key={7}
            path="/wallet-history"
            element={<WalletHistory></WalletHistory>}
          />
          <Route
            exact
            key={8}
            path="/transfer-fund"
            element={<TransferFund {...this.props}></TransferFund>}
          />
          <Route
            exact
            key={9}
            path="/edit-transaction-pin"
            element={<EditTransactionPin></EditTransactionPin>}
          />
          <Route
            exact
            key={10}
            path="/execute-orders"
            element={<Vouchers {...this.props}></Vouchers>}
          />
          <Route
            exact
            key={11}
            path="/initiate-voucher"
            element={<GenerateVoucher {...this.props}></GenerateVoucher>}
          />
          <Route
            exact
            key={12}
            path="/create-users"
            element={<CreateUser {...this.props}></CreateUser>}
          />
          <Route
            exact
            key={13}
            path="/uploaded-files"
            element={<Uploaded {...this.props}></Uploaded>}
          />
          <Route
            exact
            key={21}
            path="/upload-history"
            element={<UploadHistory {...this.props}></UploadHistory>}
          />
          <Route
            exact
            key={14}
            path="/print-units"
            element={<PrintUnits {...this.props}></PrintUnits>}
          />
          <Route
            exact
            key={15}
            path="/printed-units"
            element={<Printed {...this.props}></Printed>}
          />
          <Route
            exact
            key={16}
            path="/generate-voucher"
            element={<GenerateVoucherB {...this.props}></GenerateVoucherB>}
          />
          <Route
            exact
            key={17}
            path="/vouchers"
            element={<VouchersB {...this.props}></VouchersB>}
          />
          <Route
            exact
            key={18}
            path="/vouchers-h"
            element={<VouchersHistory {...this.props}></VouchersHistory>}
          />
          <Route
            exact
            key={19}
            path="/reprint-requests"
            element={<ReprintRequests></ReprintRequests>}
          />
          <Route
            exact
            key={21}
            path="/req-history"
            element={<FundWalletReqHistory></FundWalletReqHistory>}
          />
          <Route
            exact
            key={22}
            path="/print-invitations"
            element={<PrintInvitations></PrintInvitations>}
          />
          <Route exact key={23} path="/reports" element={<Reports></Reports>} />
          <Route
            exact
            key={20}
            path="/system-config"
            element={
              <Compliance
                {...this.props}
                walletBalance={this.state.walletBalance}
              ></Compliance>
            }
          />
        </Switch>
        <Footer></Footer>
      </div >
    );
  }
}

export default Dashboard;
