//import React
import React from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import "./App.css";
import Dashboard from "./components/dashboard";
import VerifyEmail from "./components/verifyEmail";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import AuthPage from "./components/AuthenticatorSlide";
import CompleteSignup from "./components/CompleteSignUp";
import ResetPin from "./components/ResetPin";
import SuccessPage from "./components/messages/successPage";

function App() {
  const navigate = useNavigate();

  const WrappedComponent = (props) => {
    const location = useLocation();

    return (
      <props.Component navigate={navigate} location={location} {...props} />
    );
  };
  return (
    <div>
      <Routes>
        <Route
          exact
          path="/complete-signup"
          element={
            <WrappedComponent Component={CompleteSignup}></WrappedComponent>
          }
        />
        <Route
          exact
          path="/success-page"
          element={
            <WrappedComponent Component={SuccessPage}></WrappedComponent>
          }
        />
        <Route
          exact
          path="/verifyEmail"
          element={
            <WrappedComponent Component={VerifyEmail}></WrappedComponent>
          }
        />
        <Route
          exact
          path="/authenticate"
          element={<WrappedComponent Component={AuthPage}></WrappedComponent>}
        />
        <Route
          path="/forgot-password"
          element={
            <WrappedComponent Component={ForgotPassword}></WrappedComponent>
          }
        />
        <Route
          path="/reset-password"
          element={
            <WrappedComponent Component={ResetPassword}></WrappedComponent>
          }
        />
        <Route
          path="/resetPin"
          element={<WrappedComponent Component={ResetPin}></WrappedComponent>}
        />
        <Route
          path="*"
          element={<WrappedComponent Component={Dashboard}></WrappedComponent>}
        />
      </Routes>
    </div>
  );
}

export default App;
