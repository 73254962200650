import React from "react";
import jsPDF from "jspdf";
class Airtel {
  constructor(id, pin, description, pins, businessName) {
    console.log(pins);
    console.log(businessName);
    this.state = {
      pins: pins,
      id: id,
      TOTP_Code: pin,
      description: description,
      businessName: businessName,
    };
  }

  Print() {
    var doc = new jsPDF({
      encryption: {
        userPassword: this.state.TOTP_Code,
        ownerPassword: this.state.TOTP_Code,
        userPermissions: ["print", "modify", "annot-forms"],
      },
      orientation: "l",
      unit: "pt",
    });
    //Dimension of A4 in pts: 595 × 842
    doc.setFontSize(6);
    if (
      this.state.pins[0].unitAmount == 100 ||
      this.state.pins[0].unitAmount == 200
    ) {
    } else {
      doc.setCharSpace(1);
    }
    var pageWidth = 595;
    var pageHeight = 842;

    var pageMargin = 2;

    pageWidth -= pageMargin;
    pageHeight -= pageMargin;

    var cellMargin = 2;
    var cellWidth =
      this.state.pins[0].unitAmount == 100 ||
        this.state.pins[0].unitAmount == 200
        ? pageHeight / 6.4
        : pageHeight / 6;
    var cellHeight = pageWidth / 10.5;

    var startX = pageMargin - 5;
    var startY = pageMargin;

    function createCard(item, businessName, i) {
      // doc.getTextDimensions(item.Name); turncate or split string if you needed
      if (startY >= pageWidth - 20) {
        doc.addPage();
        startY = pageMargin; // Restart height position
      }

      doc.rect(startX, startY, cellWidth, cellHeight);

      function convertStringToArray(str, maxPartSize) {
        const chunkArr = [];
        let leftStr = str;
        do {
          chunkArr.push(leftStr.substring(0, maxPartSize));
          leftStr = leftStr.substring(maxPartSize, leftStr.length);
        } while (leftStr.length > 0);

        return chunkArr;
      }

      function convertToSpacedPin(pin) {
        let str = convertStringToArray(pin, 6).join("-");
        return str;
      }

      doc.setFontSize(6);
      doc.text(
        businessName + " " + item?.orderDescription,
        startX + 5,
        startY + 7
      );
      doc.text(
        item?.unitAmount == 100 || item?.unitAmount == 200
          ? "Logical-" + item?.network + "              N" + item?.unitAmount
          : "Logical-" +
          item?.network +
          "                " +
          "N" +
          item?.unitAmount,
        startX + 5,
        startY + 13
      );
      var image = new Image();
      image.src = "assets/images/" + item?.network + ".png";
      doc.addImage(
        image,
        "PNG",
        item?.unitAmount == 100 || item?.unitAmount == 200
          ? startX + 120
          : startX + 120,
        startY,
        23,
        cellHeight
      );

      doc.text("S/Q: 000000" + item?.sequence, startX + 5, startY + 19);
      doc.text("S/N: 000000" + item?.serial, startX + 5, startY + 26);

      if (item?.unitAmount == 100 || item?.unitAmount == 200) {
        doc.setFontSize(7).setFont(undefined, "bold");
        doc.text(convertToSpacedPin(item?.pin), startX + 5, startY + 34);
      } else {
        doc.setFontSize(7).setFont(undefined, "bold");
        doc.text(convertToSpacedPin(item?.pin), startX + 5, startY + 34);
      }

      doc.setFontSize(5).setFont(undefined, "normal");
      if (item?.unitAmount == 100 || item?.unitAmount == 200) {
      } else {
        doc.setCharSpace(1);
      }
      doc.text(
        "Date: " + new Date().toLocaleDateString(),
        startX + 5,
        startY + 40
      );
      doc.text("Dial *311*PIN# to load", startX + 5, startY + 46);
      doc.text("Powered by TopUpBox", startX + 5, startY + 52);

      var nextPosX = startX + cellWidth + cellMargin;

      if (nextPosX > pageHeight) {
        startX = pageMargin - 5;
        startY += cellHeight + 2;
      } else {
        startX = nextPosX;
      }
    }

    for (var i = 0; i < this.state.pins.length; i++) {
      createCard(
        this.state.pins[i],
        this.state.businessName,
        this.convertStringToArray,
        i
      );
    }

    doc.save(this.state.description + " " + new Date().toString() + ".pdf");
  }
}

export default Airtel;
