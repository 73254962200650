let origin = window.origin;
console.log(origin);
var WEB_BASE_URL = "";
var UNIFIED_BASE_URL = "";
if (origin === "http://localhost:3000") {
  WEB_BASE_URL = origin + "/api";
  UNIFIED_BASE_URL = origin + "/unified";
} else {
  WEB_BASE_URL = origin + "/api";
  UNIFIED_BASE_URL = origin + "/unified";
}

module.exports = { WEB_BASE_URL, UNIFIED_BASE_URL };
