import axios from "axios";
import React from "react";
import cookie from "react-cookies";
import { toast } from "react-toastify";
import AuthModal from "../AuthModal";
import { WEB_BASE_URL } from "../webbase";

class Business extends React.Component {
  constructor() {
    super();
    this.state = {
      token: cookie.load("token"),
      business: {},
      banks: [
        {
          bankName: "",
          accountNumber: "",
        },
        {
          bankName: "",
          accountNumber: "",
        },
      ],
      transactionPin: "",
    };

    this.getBusiness = this.getBusiness.bind(this);
    this.getBanks = this.getBanks.bind(this);
  }

  getBusiness() {
    axios
      .get(WEB_BASE_URL + "/api/v2/w2/logical-get-user", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        console.log(res);
        this.setState({
          business: res.data.data[0],
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }

  getBanks() {
    axios
      .get(WEB_BASE_URL + "/api/v2/w2/get-banks", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.data !== null) {
          this.setState({
            banks: res.data.data.slice(0, 2),
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  editBanks() {
    const id = toast.loading("Please wait...");
    console.log(this.state.banks);
    //for each bank
    this.state.banks.slice(0, 2).forEach((i) => {
      axios
        .post(
          WEB_BASE_URL + "/api/v2/w2/add-banks",
          {
            accountNumber: i.accountNumber,
            bankName: i.bankName,
            transactionPin: this.state.transactionPin,
          },
          {
            headers: {
              authorization: this.state.token,
            },
          }
        )
        .then((res) => {
          console.log(res);
          toast.update(id, {
            render: " Bank Details Saved!!",
            type: "success",
            isLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          toast.update(id, {
            render: "Error saving bank details!!",
            type: "error",
            isLoading: false,
          });
          setTimeout(() => {
            toast.dismiss(id);
          }, 3000);
        });
    });
  }

  handleChange(e, index, type) {
    const value = e.target.value;

    const banks = [].concat(this.state.banks);
    banks[index][type] = value;
    console.log(banks[index][type]);
    this.setState({
      banks: banks,
    });

    console.log(this.state.banks);
  }

  componentDidMount() {
    this.getBusiness();
    this.getBanks();
  }

  setTransactionPin(e) {
    this.setState({
      transactionPin: e.target.value,
    });
  }

  render() {
    return (
      <div className="py-3">
        <form class="row g-3">
          <h4>Update Business Information</h4>
          <div class="col-6">
            <label class="form-label">Business Name</label>
            <input
              type="text"
              class="form-control"
              value={this.state.business.bussinessName}
              disabled
            />
          </div>

          <div class="col-6">
            <label class="form-label">Business Email address</label>
            <input
              type="text"
              class="form-control"
              value={this.state.business.emailAddress}
              disabled
            />
          </div>
          <div class="col-6">
            <label class="form-label">Contact address</label>
            <input
              type="text"
              class="form-control"
              value={this.state.business.contactAddress}
              disabled
            />
          </div>
          <div className="row col-12 mt-3">
            <label class="form-label">Bank Account 1</label>
            <div class="col-6">
              <input
                type="text"
                class="form-control"
                placeholder="Bank Name"
                onChange={(e) => {
                  this.handleChange(e, 0, "bankName");
                }}
                value={
                  this.state.banks[0].bankName === undefined
                    ? ""
                    : this.state.banks[0].bankName
                }
              />
            </div>

            <div class="col-6">
              <input
                type="text"
                class="form-control"
                placeholder="Account Number"
                onChange={(e) => {
                  this.handleChange(e, 0, "accountNumber");
                }}
                value={
                  this.state.banks[0].accountNumber === undefined
                    ? ""
                    : this.state.banks[0].accountNumber
                }
              />
            </div>
            <label class="form-label mt-3">Bank Account 2</label>
            <div class="col-6">
              <input
                type="text"
                class="form-control"
                placeholder="Bank Name"
                onChange={(e) => {
                  this.handleChange(e, 1, "bankName");
                }}
                value={
                  this.state.banks[1].bankName === undefined
                    ? ""
                    : this.state.banks[1].bankName
                }
              />
            </div>
            <div class="col-6">
              <input
                type="text"
                class="form-control"
                placeholder="Account Number"
                onChange={(e) => {
                  this.handleChange(e, 1, "accountNumber");
                }}
                value={
                  this.state.banks[1].accountNumber === undefined
                    ? ""
                    : this.state.banks[1].accountNumber
                }
              />
            </div>
          </div>
          <AuthModal
            title="Enter Authenticator Code"
            id="AuthModal"
            formPin={this.state.transactionPin}
            setPin={this.setTransactionPin.bind(this)}
            onSubmit={this.editBanks.bind(this)}
          ></AuthModal>
        </form>
        <div className="row my-4 px-3 gap-1">
          <button
            data-bs-toggle="modal"
            data-bs-target="#AuthModal"
            type="button"
            class="btn btn-primary px-2  col-md-2"
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}

export default Business;
