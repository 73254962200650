import React from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import cookie from "react-cookies";
import { UNIFIED_BASE_URL, WEB_BASE_URL } from "./webbase";
import queryString from "query-string";
class SignIn extends React.Component {
  constructor(props) {
    super(props);
    console.log(this.props);
    this.state = {
      history: this.props.history,
      formEmail: "",
      formPassword: "",
      user: ""
    };

    //bind all events
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
  }

  onChangeEmail = (event) => {
    this.setState({
      formEmail: event.target.value,
    });
  };

  onChangePassword = (event) => {
    this.setState({
      formPassword: event.target.value,
    });
  };


  onSubmit = (event) => {
    event.preventDefault();
    const id = toast.loading("Please wait...");

    console.log({
      password: this.state.formPassword,
      emailAddress: this.state.formEmail,
    });
    //axios post to sign in
    axios
      .post(UNIFIED_BASE_URL + "/onboarding/login", {
        password: this.state.formPassword,
        emailAddress: this.state.formEmail,
      })
      .then((res) => {
        let iflog = res.data.status == 2000 ? true : false;
        console.log(res);
        if (iflog) {
          toast.update(id, {
            render: "Successfully Signed In",
            type: "success",
            isLoading: false,
          });
          axios
            .get(UNIFIED_BASE_URL + "/onboarding/get-menu?product=logical", {
              headers: {
                Authorization: res.data.data.token,
              },
            }).then(sidebar => {
              let userRole = res.data.data.userRole[0].toUpperCase() + res.data.data.userRole.slice(1).toLowerCase();
              if (userRole == "Merchant") {
                userRole = "Merchants";
              }
              let SideObj = sidebar.data.data.sidebarMenu.filter(item => item.role == userRole || item.role == "all" || item.role == "DEALER");
              console.log(sidebar.data.data.sidebarMenu);
              console.log(SideObj)
              if (!res.data.data.activateToken) {
                axios
                  .get(UNIFIED_BASE_URL + "/onboarding/setup-2fa", {
                    headers: {
                      Authorization: res.data.data.token,
                    },
                  })
                  .then((res_fa) => {
                    console.log(res_fa);
                    this.props.navigate("/authenticate", {
                      state: {
                        secret: res_fa.data.data.qrCode,
                        token: res.data.data.token,
                        if2FA: res.data.data.activateToken,
                        user: res_fa.data.data.username,
                        dealerId: res.data.data.dealerId,
                        userRole: res.data.data.userRole,
                        comingFrom: "Login",
                        description: res.data.description,
                        userName:
                          res.data.data.firstName + " " + res.data.data.lastName,
                        sideBar: SideObj[0].Side,
                        walletBalance: res.data.data.accountBalance,
                      },
                    });
                  });
              } else {
                //alert(res.data.description);
                this.props.navigate("/authenticate", {
                  state: {
                    secret: res.data.data.qrCode,
                    token: res.data.data.token,
                    if2FA: res.data.data.activateToken,
                    user: res.data.data.username,
                    dealerId: res.data.data.dealerId,
                    userRole: res.data.data.userRole,
                    comingFrom: "Login",
                    description: res.data.description,
                    userName:
                      res.data.data.firstName + " " + res.data.data.lastName,
                    sideBar: SideObj[0].Side,
                    walletBalance: res.data.data.accountBalance,
                  },
                });
              }
            });
        } else {
          console.log(res);
          toast.update(id, {
            render: "" + res.data.description,
            type: "error",
            isLoading: false,
          });
          setTimeout(() => {
            toast.dismiss(id);
          }, 2000);
        }
      })
      .catch((err) => {
        toast.update(id, {
          render: "" + err,
          type: "error",
          isLoading: false,
        });
        setTimeout(() => {
          toast.dismiss(id);
        }, 2000);
      });
  };


  componentDidMount() {

  }

  render() {
    return (
      <div class="">
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <main class="authentication-content">
          <div class="container-fluid">
            <div class="authentication-card">
              <div class="card shadow rounded-0 overflow-hidden">
                <div class="row g-0">
                  <div class="col-lg-6 bg-login d-flex align-items-center justify-content-center">
                    <img
                      src="assets/images/error/login-img.png"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <div class="col-lg-6">
                    <div class="card-body p-4 p-sm-5">
                      <h5 class="card-title">Logical - Sign In</h5>
                      <p class="card-text mb-5">Login to get started!</p>
                      <form
                        class="form-body"
                        onSubmit={(event) => {
                          event.preventDefault();
                          this.onSubmit(event);
                        }}
                      >
                        <div class="row g-3">
                          <div class="col-12">
                            <div class="ms-auto position-relative">
                              <div class="position-absolute top-50 translate-middle-y search-icon px-3">
                                <i class="bi bi-envelope-fill"></i>
                              </div>
                              <input
                                type="email"
                                class="form-control radius-30 ps-5"
                                id="inputEmailAddress"
                                placeholder="Email Address"
                                required={true}
                                onChange={this.onChangeEmail}
                              />
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="ms-auto position-relative">
                              <div class="position-absolute top-50 translate-middle-y search-icon px-3">
                                <i class="bi bi-lock-fill"></i>
                              </div>
                              <input
                                type="password"
                                class="form-control radius-30 ps-5"
                                id="inputChoosePassword"
                                placeholder="Enter Password"
                                required={true}
                                onChange={this.onChangePassword}
                              />
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-check form-switch">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                id="flexSwitchCheckChecked"
                                checked=""
                              />
                              <label
                                class="form-check-label"
                                htmlFor="flexSwitchCheckChecked"
                              >
                                Remember Me
                              </label>
                            </div>
                          </div>
                          <div class="col-6 text-end">
                            {" "}
                            <a href="/forgot-password">Forgot Password ?</a>
                          </div>
                          <div class="col-12">
                            <div class="d-grid">
                              <button
                                type="submit"
                                class="btn btn-primary radius-30"
                              >
                                Sign In
                              </button>
                            </div>
                          </div>
                          <div class="col-12 text-center">
                            {/* <p class="mb-0">
                              Don't have an account yet?{" "}
                              <a href="/signUp">Sign up here</a>
                            </p> */}
                            <div class="breadcrumb-item active mt-3">
                              Copyright © 2022 TopUpBox. All rights reserved.
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }

}

export default SignIn;
