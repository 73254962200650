import React from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ForgotPinModal from "./ForgotPinmodal";

class EditTransactionPin extends React.Component {
  constructor() {
    super();
    this.state = {
      someKey: "someValue",
    };
    this.onSave = this.onSave.bind(this);
    this.handleForgot = this.handleForgot.bind(this);
    this.onSubmitNewTransactionPin = this.onSubmitNewTransactionPin.bind(this);
  }

  onSave(event) {
    event.preventDefault();
    const id = toast.loading("Please wait...");
    axios
      .post(
        "http://localhost:8010/proxy/services/api/v2/u1/unicard-users-change-password",
        {
          newPassword: this.state.formPassword,
        },
        {
          headers: {
            authorization: this.state.token,
          },
        }
      )
      .then((res) => {
        console.log(res);
        toast.update(id, {
          render: "Profile Details Saved!!",
          type: "success",
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log("hi");
        console.log(err);
      });
  }

  handleForgot = () => {
    //request forgot pin onTransactionPinChange
    const id = toast.loading("Please wait...");
    this.setState({});
  };

  onSubmitNewTransactionPin() {
    toast.success("New Transaction Pin Saved!!!");
  }

  render() {
    return (
      <main className="page-content">
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <ForgotPinModal
          title="Forgot Pin"
          id="ForgotModal"
          onSubmit={this.onSubmitNewTransactionPin}
        ></ForgotPinModal>
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="javascript:;">
                    <i className="bx bx-home-alt"></i>
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Reset Transaction Pin
                </li>
              </ol>
            </nav>
          </div>
          <div className="ms-auto">
            <div className="btn-group">
              <button type="button" className="btn btn-primary">
                Wallet Balance:
              </button>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <form class="row g-3">
              <div class="col-6">
                <label class="form-label">New Pin</label>
                <input type="password" class="form-control" value="@jhon" />
              </div>
              <div class="col-6">
                <label class="form-label">Confirm New Pin</label>
                <input type="password" class="form-control" value="@jhon" />
              </div>
              <div class="text-start">
                <button
                  onClick={(e) => {
                    this.onSave(e);
                  }}
                  type="button"
                  class="btn btn-primary px-4"
                >
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </main>
    );
  }

  componentDidMount() {
    this.setState({
      someKey: "otherValue",
    });
  }
}

export default EditTransactionPin;
