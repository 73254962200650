import React from "react";
import Select from "react-select";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { WEB_BASE_URL } from "./webbase";
import queryString from "query-string";

class CompleteSignup extends React.Component {
  constructor(props) {
    super(props);
    console.log(this.props);
    const query = queryString.parse(this.props.location.search);
    this.state = {
      uuid: query.uuid ? query.uuid : "",

      stateOptions: [{ value: "Abia", label: "Abia" }],
      history: this.props.history,
      // input variables
      formEmail: "",
      dealer: "",
      formPassword: "",
      formConfirmPassword: "",
      formFirstName: "",
      formLastName: "",
      formPhone: "",
      formState: "",
      formLocation: "",
      formDealerName: "",
    };

    //bind all functions
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeRole = this.onChangeRole.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeFirst = this.onChangeFirst.bind(this);
    this.onChangeLast = this.onChangeLast.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangeLocation = this.onChangeLocation.bind(this);
    this.onChangeDealerName = this.onChangeDealerName.bind(this);
    this.onChangeDealerName = this.onChangeDealerName.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeDealer = this.onChangeDealer.bind(this);
    this.onChangeState = this.onChangeState.bind(this);
    this.getStateOptions = this.getStateOptions.bind(this);
  }

  //Onsubmit
  onSubmit = (e) => {
    e.preventDefault();

    if (this.state.uuid == "") {
      toast.error("Invalid Request");
      return;
    }
    const user = this.state.formFirstName.split(" ");
    const id = toast.loading("Please wait...");
    axios
      .post(WEB_BASE_URL + "/api/v2/w2/logical-users-complete-signup", {
        password: this.state.formPassword,
        phoneNumber: this.state.formPhone,
        uuid: this.state.uuid,
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status == 2000) {
          toast.update(id, {
            render: "Registration Complete",
            type: "success",
            isLoading: false,
          });
          this.props.navigate("/authenticate", {
            state: {
              secret: res.data.data.qrCode,
              token: res.data.data.jwtToken,
              comingFrom: "complete-Signup",
            },
          });
        } else {
          toast.update(id, {
            render: "" + res.data.description,
            type: "error",
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        toast.update(id, {
          render: "" + err,
          type: "error",
          isLoading: false,
        });
        setTimeout(() => {
          toast.dismiss(id);
        }, 2000);
      });
  };

  getStateOptions = () => {
    axios
      .get(WEB_BASE_URL + "/api/v2/w2/get-states")
      .then((res) => {
        console.log(res.data.data);
        let stateOptions = res.data.data.map((state) => {
          return { value: state.area, label: state.area };
        });
        this.setState({ stateOptions: stateOptions });
      })
      .catch((err) => {
        console.log(WEB_BASE_URL + " " + err);
      });
  };

  //set state for formEmail
  onChangeEmail = (e) => {
    this.setState({
      formEmail: e.target.value,
    });
  };

  //set state for formRole
  onChangeRole = (e) => {
    this.setState({
      formRole: e.target.value,
    });
  };

  onChangeDealer = (e) => {
    this.setState({
      formDealerName: e.value,
    });
  };

  //set state for formPassword
  onChangePassword = (e) => {
    this.setState({
      formPassword: e.target.value,
    });
  };

  // set state for formFirst
  onChangeFirst = (e) => {
    this.setState({
      formFirstName: e.target.value,
    });
  };

  // set state for formLast
  onChangeLast = (e) => {
    this.setState({
      formLastName: e.target.value,
    });
  };

  // set state for formPhone
  onChangePhone = (e) => {
    this.setState({
      formPhone: e.target.value,
    });
  };

  //set state for formLocation
  onChangeLocation = (e) => {
    this.setState({
      formLocation: e.target.value,
    });
  };

  //set state for formDealerName
  onChangeDealerName = (e) => {
    this.setState({
      formDealerName: e.value,
    });
  };

  componentDidMount() {
    //this.verifyDealer();
    this.getStateOptions();
  }

  onChangeState = (e) => {
    this.setState({
      formState: e.value,
    });
  };

  render() {
    const customStyles = {
      menu: (provided, state) => ({
        ...provided,
        borderBottom: "1px dotted pink",
        color: state.selectProps.menuColor,
        padding: 20,
      }),

      control: (provided, { selectProps: { width } }) => ({
        ...provided,
        borderRadius: "25px",
        padding: "2px 4px",
        width: width,
        minWidth: "310px",
      }),
    };
    return (
      <div>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <main className="authentication-content">
          <div className="container-fluid">
            <div className="authentication-card">
              <div className="card shadow rounded-0 overflow-hidden">
                <div className="row g-0">
                  <div className="col-lg-6 bg-login d-flex align-items-center justify-content-center">
                    <img
                      src="assets/images/error/login-img.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="col-lg-6">
                    <div className="card-body p-4 p-sm-5">
                      <h5 className="card-title">Logical - Complete Sign Up</h5>
                      <p className="card-text mb-2">Register to get started!</p>
                      {/* <p>Dealer Name: {this.state.dealer}</p> */}
                      <form
                        className="form-body"
                        onSubmit={(event) => {
                          event.preventDefault();
                          this.onSubmit(event);
                        }}
                      >
                        <div className="row g-3">
                          <div
                            className="col-12"
                            styles={{ marginTop: 10 + "px" }}
                          >
                            <div className="ms-auto position-relative">
                              <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                                <i className="bi bi-person-circle"></i>
                              </div>
                              <input
                                className="form-control radius-30 ps-5"
                                id="inputName"
                                type="tel"
                                placeholder="Phone Number"
                                onChange={this.onChangePhone}
                                value={this.state.formPhone}
                                required
                              />
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="ms-auto position-relative">
                              <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                                <i className="bi bi-lock-fill"></i>
                              </div>
                              <input
                                type="password"
                                className="form-control radius-30 ps-5"
                                id="inputChoosePassword"
                                placeholder="Enter Password"
                                onChange={this.onChangePassword}
                                value={this.state.formPassword}
                                required
                              />
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="flexSwitchCheckChecked"
                              />
                              <label
                                className="form-check-label"
                                for="flexSwitchCheckChecked"
                              >
                                I Agree to the{" "}
                                <a href="/">Terms & Conditions</a>
                              </label>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="d-grid">
                              <button
                                type="submit"
                                className="btn btn-primary radius-30"
                              >
                                Get Started
                              </button>
                            </div>
                          </div>
                          <div className="col-12 text-center">
                            <p className="mb-0">
                              Already have an account?{" "}
                              <a href="/signIn">Sign in here</a>
                            </p>
                            <div class="breadcrumb-item active mt-2">
                              Copyright © 2022 TopUpBox. All rights reserved.
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default CompleteSignup;
