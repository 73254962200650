import React from "react";
import axios from "axios";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Voucheritem from "./voucheritem";
import { UNIFIED_BASE_URL, WEB_BASE_URL } from "./webbase";
import cookie from "react-cookies";
import { ToastContainer, toast } from "react-toastify";

class Main extends React.Component {
  constructor() {
    super();
    this.state = {
      token: cookie.load("token"),
      businessName: cookie.load("businessName"),
      walletBalance: 0,
      vouchers: [],
      boughtvouchers: [],
      userRole: cookie.load("userRole"),
      networks: [],
      dailyReport: [],
      avaliableVouchers: {},
    };
    this.getVouchers = this.getVouchers.bind(this);
    this.getWalletBalance = this.getWalletBalance.bind(this);
    this.getBoughtVouchers = this.getBoughtVouchers.bind(this);
    this.getNetworks = this.getNetworks.bind(this);
    this.getDailyReport = this.getDailyReport.bind(this);
    this.getAvaliable = this.getAvaliable.bind(this);
  }

  getVouchers = () => {
    axios
      .get(WEB_BASE_URL + "/api/v2/w2/planned-order", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.data !== null) {
          res.data.data.reverse();
          this.setState({ vouchers: res.data.data });
        }
      })
      .catch((err) => {
        console.log(this.state.token);
        toast.error(err);
      });
  };

  getWalletBalance() {
    axios
      .get(WEB_BASE_URL + "/api/v2/w2/get-account-balance", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        console.log(res.data);
        cookie.save("walletBalance", res.data.accountBalance, {
          path: "/",
          expires: new Date(Date.now() + 60 * 30 * 1000),
        });

        this.setState({ walletBalance: res.data.data.accountBalance });
      })
      .catch((err) => { })
      .then(() => { });
  }

  getBoughtVouchers = () => {
    axios
      .get(WEB_BASE_URL + "/api/v2/w2/get-voucher", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        if (res.data.description == "No data available") {
        } else {
          console.log(res.data);
          res.data.data.reverse();
          this.setState({ boughtvouchers: res.data.data });
        }
      })
      .catch((err) => { });
  };

  getNetworks() {
    axios
      .get(WEB_BASE_URL + "/api/v2/w2/get-network", {}, {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        console.log(res.data)
        if (res.data.status !== "403") {
          let networks = res.data.data.map((network) => {
            return { name: network.network, status: network.status };
          });
          console.log(networks.length);
          if (networks.length === 0) {
            toast.error("No Networks Found, Please Setup Config");
          }
          this.setState({ networks: networks });
        }
      })
      .catch((err) => {
        console.log(WEB_BASE_URL + " " + err);
      });
  }

  getDailyReport() {
    axios
      .get(WEB_BASE_URL + "/api/v2/w2/daily-report", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        if (res.data.status === "2000" && res.data.data !== null) {
          console.log(res.data.data);

          this.setState({ dailyReport: res.data.data });
        }
      })
      .catch((err) => {
        console.log(WEB_BASE_URL + " " + err);
      });
  }

  getAvaliable() {
    axios
      .get(WEB_BASE_URL + "/api/v2/w2/available-voucher", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        if (res.data.status === "2000" && res.data.data !== null) {
          this.setState({ avaliableVouchers: res.data.data });
        }
      })
      .catch((err) => {
        console.log(WEB_BASE_URL + " " + err);
      });
  }

  componentDidMount() {
    const user = cookie.load("user");
    const userRole = cookie.load("userRole");
    if (user !== undefined) {
      this.getWalletBalance();
      this.getBoughtVouchers();
      if (
        userRole === "DEALER" ||
        userRole === "MANAGER" ||
        userRole === "ADMINISTRATOR" ||
        userRole === "ACCOUNT_OFFICER"
      ) {
        this.getVouchers();
        this.getDailyReport();
        this.getNetworks();
        this.getAvaliable();
      }
    }
  }

  render() {
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
    });
    return (
      <div className="page-content">
        <ToastContainer
          position="top-center"
          autoClose={false}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={true}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-4">
          <div className="col">
            <div className="card overflow-hidden radius-10">
              <div className="card-body">
                <div className="d-flex align-items-stretch justify-content-between overflow-hidden pb-2">
                  <div className="">
                    <p>Wallet Balance</p>
                    <h5 className="">
                      {formatter.format(this.state.walletBalance)}
                    </h5>
                  </div>
                  <div className="w-50">
                    <p className="mb-3 float-end text-success">funds</p>
                    <div id="chart1"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.userRole === "DEALER" ||
            this.state.userRole === "ADMINISTRATOR" ||
            this.state.userRole === "MANAGER" ||
            this.state.userRole === "ACCOUNT_OFFICER" ? (
            // planned Orders
            <>
              <div className="col">
                <div className="card overflow-hidden radius-10">
                  <div className="card-body">
                    <div className="d-flex align-items-stretch justify-content-between overflow-hidden">
                      <div className="w-50">
                        <p>Planned Orders</p>
                        <h4 className="">{this.state.vouchers.length}</h4>
                      </div>
                      <div className="w-50">
                        <p className="mb-3 float-end text-danger">
                          your stocks
                        </p>
                        <div id="chart2"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card overflow-hidden radius-10">
                  <div className="card-body">
                    <div className="d-flex align-items-stretch justify-content-between overflow-hidden">
                      <div className="w-50">
                        <p>Transactions</p>
                        <h4 className="">0</h4>
                      </div>
                      <div className="w-50">
                        <p className="mb-3 float-end text-success">your flow</p>
                        <div id="chart4"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card overflow-hidden radius-10">
                  <div className="card-body">
                    <div className="d-flex align-items-stretch justify-content-between overflow-hidden">
                      <div className="">
                        <p>Funding Requests</p>
                        <h4 className="">0</h4>
                      </div>
                      <div className="">
                        <p className="mb-3 float-end text-success">
                          your sales
                        </p>
                        <div id="chart4"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {this.state.networks.map((network) => (
                <>
                  <div className="col">
                    <div className="card overflow-hidden radius-10">
                      <div className="card-body">
                        <div className="d-flex align-items-stretch justify-content-between overflow-hidden pb-2">
                          <div className="">
                            <p>Cost Price ({network?.name})</p>
                            <h4 className="">
                              {this.state.dailyReport[
                                "Total CostPrice " + network?.name
                              ] !== undefined
                                ? formatter.format(this.state.dailyReport[
                                  "Total CostPrice " + network?.name
                                ])
                                : 0}
                            </h4>
                          </div>
                          <div className="">
                            <p className="mb-3 float-end text-success">
                              Daily sales
                            </p>
                            <div id="chart3"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card overflow-hidden radius-10">
                      <div className="card-body">
                        <div className="d-flex align-items-stretch justify-content-between overflow-hidden pb-2">
                          <div className="w-50">
                            <p>Profit ({network?.name})</p>
                            <h4 className="">
                              {this.state.dailyReport[
                                "Total DailyProft " + network?.name
                              ] !== undefined
                                ? formatter.format(this.state.dailyReport[
                                  "Total DailyProft " + network?.name
                                ])
                                : 0}
                            </h4>
                          </div>
                          <div className="">
                            <p className="mb-3 float-end text-success">Daily</p>
                            <div id="chart3"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card overflow-hidden radius-10">
                      <div className="card-body">
                        <div className="d-flex align-items-stretch justify-content-between overflow-hidden pb-2">
                          <div className="">
                            <p>Total Available Inventory ({network?.name})</p>
                            <h4 className="">
                              {
                                this.state.avaliableVouchers[
                                "Available Voucher " + network?.name + "CUMM"
                                ]
                              }
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </>
          ) : (
            <>
              <div className="col">
                <div className="card overflow-hidden radius-10">
                  <div className="card-body">
                    <div className="d-flex align-items-stretch justify-content-between overflow-hidden">
                      <div className="w-50">
                        <p>Vouchers</p>
                        <h4 className="">{this.state.boughtvouchers.length}</h4>
                      </div>
                      <div className="w-50">
                        <p className="mb-3 float-end text-danger">
                          your stocks
                        </p>
                        <div id="chart2"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card overflow-hidden radius-10">
                  <div className="card-body">
                    <div className="d-flex align-items-stretch justify-content-between overflow-hidden pb-2">
                      <div className="w-50">
                        <p>Printed Vouchers</p>
                        <h4 className="">0</h4>
                      </div>
                      <div className="w-50">
                        <p className="mb-3 float-end text-success">
                          your sales
                        </p>
                        <div id="chart3"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        {this.state.userRole === "DEALER" ||
          this.state.userRole === "ADMINISTRATOR" ||
          this.state.userRole === "MANAGER" ||
          this.state.userRole === "ACCOUNT_OFFICER" ? (
          // planned Orders
          <>
            <div className="row">
              <div className="col-12 col-lg-12 col-xl-12 d-flex">
                <div className="card radius-10 w-100">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <h6 className="mb-0">Recent Planned Orders</h6>
                      <div className="fs-5 ms-auto dropdown">
                        <div
                          className="dropdown-toggle dropdown-toggle-nocaret cursor-pointer"
                          data-bs-toggle="dropdown"
                        >
                          <i className="bi bi-three-dots"></i>
                        </div>
                        <ul className="dropdown-menu">
                          <li>
                            <a className="dropdown-item" href="#">
                              Action
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              Another action
                            </a>
                          </li>
                          <li>
                            <hr className="dropdown-divider" />
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              Something else here
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="table-responsive mt-2">
                      <table className="table align-middle mb-0">
                        <thead className="table-light">
                          <tr>
                            <th>Batch ID</th>
                            <th>Description</th>
                            <th>Unit Quantity</th>
                            <th>Network</th>
                            <th>Voucher denomination</th>
                            <th>Cost Price</th>
                            <th>Total Amount</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.vouchers.map((voucher) => (
                            <tr>
                              <td>{voucher.id}</td>
                              <td>
                                <div className="d-flex align-items-center gap-3">
                                  <div className="product-info">
                                    <h6 className="product-name mb-1">
                                      {voucher.orderDescription}
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>{voucher.units}</td>
                              <td>{voucher.network}</td>
                              <td>{formatter.format(voucher.unitAmount)}</td>
                              <td>{voucher.costPrice}</td>
                              <td>{formatter.format(voucher.totalAmount)}</td>
                              <td>
                                {voucher.dateInitiated !== null
                                  ? voucher.dateInitiated.split("T")[0]
                                  : 0}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          //Bought Vouchers
          <></>
        )}
        <div className="row">
          <div className="col-12 col-lg-12 col-xl-12 d-flex">
            <div className="card radius-10 w-100">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <h6 className="mb-0">Recent Bought Vouchers</h6>
                  <div className="fs-5 ms-auto dropdown">
                    <div
                      className="dropdown-toggle dropdown-toggle-nocaret cursor-pointer"
                      data-bs-toggle="dropdown"
                    >
                      <i className="bi bi-three-dots"></i>
                    </div>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="#">
                          Action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Another action
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Something else here
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="table-responsive mt-2">
                  <table className="table align-middle mb-0">
                    <thead className="table-light">
                      <tr>
                        <th>Batch ID</th>
                        <th>Description</th>
                        <th>Unit Quantity</th>
                        <th>Network</th>
                        <th>Voucher denomination</th>
                        <th>Selling Price</th>
                        <th>Total Amount</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.boughtvouchers.length !== 0 ? (
                        this.state.boughtvouchers.map((voucher) => (
                          <tr>
                            <td>{voucher.id}</td>
                            <td>
                              <div className="d-flex align-items-center gap-3">
                                <div className="product-info">
                                  <h6 className="product-name mb-1">
                                    {voucher.orderDescription}
                                  </h6>
                                </div>
                              </div>
                            </td>
                            <td>{voucher.units}</td>
                            <td>{voucher.network}</td>
                            <td>{formatter.format(voucher.unitAmount)}</td>
                            <td>{voucher.sellingPrice}</td>
                            <td>{formatter.format(voucher.totalAmount)}</td>
                            <td>
                              {voucher.createdDate !== undefined
                                ? voucher.createdDate.split("T")[0]
                                : 0}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr colspan="7">
                          <td>No Bought Vouchers</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Main;
