/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import React from "react";
import cookie from "react-cookies";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ForgotPinModal from "./ForgotPinmodal";
import { UNIFIED_BASE_URL, WEB_BASE_URL } from "./webbase";

class Profile extends React.Component {
  constructor() {
    super();
    this.state = {
      token: cookie.load("token"),
      userRole: cookie.load("userRole"),
      formPassword: "",
      confirmPassword: "",
      user: {},
    };
    this.onSave = this.onSave.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }

  onChangePassword = (event) => {
    this.setState({
      formPassword: event.target.value,
    });
  };

  onChangeConfirmPassword = (event) => {
    this.setState({
      confirmPassword: event.target.value,
    });
  };

  onSave(event) {
    event.preventDefault();
    if (
      this.state.confirmPassword === this.state.formPassword &&
      this.state.confirmPassword !== ""
    ) {
      const id = toast.loading("Please wait...");
      axios
        .post(
          WEB_BASE_URL + "/api/v2/w2/logical-change-password",
          {
            newPassword: this.state.formPassword,
          },
          {
            headers: {
              authorization: this.state.token,
            },
          }
        )
        .then((res) => {
          toast.update(id, {
            render: "Profile Details Saved!!",
            type: "success",
            isLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  getProfile() {
    //toast loading

    axios
      .get(UNIFIED_BASE_URL + "/onboarding/get-user", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        this.setState({
          user: res.data.data[0],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  changePassword() {
    const id = toast.loading("Please wait...");
    if (this.state.confirmPassword === this.state.formPassword) {
      axios
        .post(
          UNIFIED_BASE_URL + "/onboarding/change-password",
          {
            headers: {
              authorization: this.state.token,
            },
          },
          {
            newPassword: "string",
          }
        )
        .then((res) => {
          console.log(res);
          toast.update(id, {
            render: "Profile Details Saved!!",
            type: "success",
          });
        })
        .catch((err) => {
          console.log(err);
          toast.dismiss(id);
          toast.error(id, {
            render: "" + err,
            type: "error",
            autoClose: "2000",
          });
        });
    } else {
      toast.update(id, {
        render: "Password Mismatch",
        type: "error",
        isLoading: false,
      });
    }
  }

  componentDidMount() {
    this.getProfile();
  }

  render() {
    return (
      <main class="page-content">
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div class="page-breadcrumb d-none d-sm-flex align-items-center">
          <div class="ps-3">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb mb-0 p-0">
                <li class="breadcrumb-item">
                  <a href="javascript:;">
                    <i class="bx bx-home-alt text-white"></i>
                  </a>
                </li>
                <li
                  class="breadcrumb-item active text-white"
                  aria-current="page"
                >
                  User Profile
                </li>
              </ol>
            </nav>
          </div>
          <div class="ms-auto">
            <div class="btn-group">
              <button type="button" class="btn btn-light">
                Settings
              </button>
              <button
                type="button"
                class="btn btn-light split-bg-light dropdown-toggle dropdown-toggle-split"
                data-bs-toggle="dropdown"
              >
                {" "}
                <span class="visually-hidden">Toggle Dropdown</span>
              </button>
              <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg-end">
                {" "}
                <a class="dropdown-item" href="javascript:;">
                  Action
                </a>
                <a class="dropdown-item" href="javascript:;">
                  Another action
                </a>
                <a class="dropdown-item" href="javascript:;">
                  Something else here
                </a>
                <div class="dropdown-divider"></div>{" "}
                <a class="dropdown-item" href="javascript:;">
                  Separated link
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="profile-cover bg-dark"></div>

        <div class="row">
          <div class="col-12 col-lg-8">
            <div class="card shadow-sm border-0">
              <div class="card-body">
                <h5 class="mb-0">My Account</h5>
                <hr />
                <div class="card shadow-none border">
                  <div class="card-header">
                    <h6 class="mb-0">USER INFORMATION</h6>
                  </div>
                  <div class="card-body">
                    <form class="row g-3">
                      <div class="col-6">
                        <label class="form-label">Full Name</label>
                        <input
                          type="text"
                          class="form-control"
                          value={
                            this.state.user.firstName +
                            " " +
                            this.state.user.lastName
                          }
                          disabled
                        />
                      </div>

                      <div class="col-6">
                        <label class="form-label">Email address</label>
                        <input
                          type="text"
                          class="form-control"
                          value={this.state.user.emailAddress}
                          disabled
                        />
                      </div>
                      <div class="col-6">
                        <label class="form-label">Phone Number</label>
                        <input
                          type="text"
                          class="form-control"
                          value={this.state.user.phoneNumber}
                          disabled
                        />
                      </div>
                      <div class="col-6">
                        <label class="form-label">Dealer Name</label>
                        <input
                          type="text"
                          class="form-control"
                          value={this.state.user.bussinessName}
                          disabled
                        />
                      </div>

                      <div class="col-6">
                        <label class="form-label">Role</label>
                        <input
                          type="text"
                          class="form-control"
                          value={this.state.userRole}
                          disabled
                        />
                      </div>
                    </form>
                  </div>
                </div>
                <div class="card shadow-none border">
                  <div class="card-header">
                    <h6 class="mb-0">Update Password</h6>
                  </div>
                  <div class="card-body">
                    <form class="row g-3">
                      <div class="col-6">
                        <label class="form-label">New Password</label>
                        <input
                          type="password"
                          class="form-control"
                          onChange={this.onChangePassword}
                        />
                      </div>
                      <div class="col-6">
                        <label class="form-label">Confirm New Password</label>
                        <input
                          type="password"
                          class="form-control"
                          onChange={this.onChangeConfirmPassword}
                        />
                      </div>
                    </form>
                  </div>
                </div>
                <div class="text-start">
                  <button
                    onClick={(e) => {
                      this.onSave(e);
                    }}
                    type="button"
                    class="btn btn-primary px-4"
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-4">
            <div class="card shadow-sm border-0 overflow-hidden">
              <div class="card-body">
                <div class="profile-avatar text-center">
                  <img
                    src="assets/images/dummy.png"
                    class="rounded-circle shadow"
                    width="120"
                    height="120"
                    alt=""
                  />
                </div>

                <div class="text-center mt-4">
                  <h4 class="mb-1"></h4>

                  <div class="mt-4"></div>
                  <h6 class="mb-1"></h6>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Profile;
