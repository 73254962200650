import axios from "axios";
import React from "react";
import cookie from "react-cookies";
import { toast } from "react-toastify";
import { WEB_BASE_URL } from "./webbase";

class UploadModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      someKey: "someValue",
      token: cookie.load("token"),
      selectedFile: {},
      pin: "",
      isLoading: false,
      message: "Awaiting Upload...",
    };

    this.onSelectFile = this.onSelectFile.bind(this);
    this.upload = this.upload.bind(this);
    this.setPin = this.setPin.bind(this);
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      this.setState({
        selectedFile: file,
      });
    }
  }

  setPin(event) {
    this.setState({
      pin: event.target.value,
    });
  }

  upload(orderId) {
    this.setState({
      isLoading: true,
    });
    const formData = new FormData();
    formData.append("orderId", orderId);
    formData.append("file", this.state.selectedFile);
    formData.append("transactionPin", this.state.pin);

    axios
      .post(WEB_BASE_URL + "/api/v2/w2/execute-order", formData, {
        timeout: 5600000,
        maxContentLength: 100000000,
        maxBodyLength: 1000000000,
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: this.state.token,
        },
        onUploadProgress: function (p) {
          // Do whatever you want with the native progress event
          const progress = p.loaded / p.total;
        },
      })
      .then((response) => {
        if (response.data.status == 2000) {
          this.setState({
            message: response.data.description,
          });

          setTimeout(() => {
            this.props.navigate("/upload-history");
            this.props.navigate(0);
          }, 1000);
        } else {
          this.setState({
            message: response.data.description,
          });
        }
      })
      .catch((error) => {
        console.log(formData);
        this.setState({
          message: error,
        });
      });
  }

  render() {
    return (
      <div
        className="modal fade"
        id={this.props.id}
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {this.props.title}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {!this.state.isLoading ? (
                <form
                  className="row g-3"
                  onSubmit={(e) => {
                    e.preventDefault();
                    this.upload(this.props.batchId);
                  }}
                >
                  <div className="col-6">
                    <label className="form-label">Voucher Description</label>
                    <input
                      type="text"
                      name="description"
                      className="form-control"
                      value={this.props.formDescription}
                      onChange={(event) => this.props.setDescription(event)}
                      required
                      disabled
                    />
                  </div>
                  <div className="col-6">
                    <label className="form-label">Network</label>
                    <input
                      type="text"
                      name="network"
                      className="form-control"
                      value={this.props.formNetwork}
                      onChange={(event) => this.props.setNetwork(event)}
                      required
                      disabled
                    />
                  </div>
                  <div className="col-6">
                    <label className="form-label">Choose File</label>
                    <input
                      type="file"
                      className="form-control"
                      onChange={(e) => this.onSelectFile(e)}
                    />
                  </div>
                  <div className="col-6">
                    <label className="form-label">Authenticator Code</label>
                    <input
                      type="password"
                      className="form-control"
                      autoComplete="new-password"
                      onChange={(event) => this.setPin(event)}
                    />
                  </div>

                  <div className="col-4">
                    <button type="submit" className="btn btn-primary">
                      Upload
                    </button>
                  </div>
                </form>
              ) : (
                <div
                  className="d-flex "
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 20,
                    padding: 20 + "px",
                  }}
                >
                  {this.state.message}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.setState({
      someKey: "otherValue",
    });
  }
}

export default UploadModal;
