/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import axios from "axios";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Voucheritem from "./voucheritem";
import { UNIFIED_BASE_URL, WEB_BASE_URL } from "./webbase";
import cookie from "react-cookies";
import { ToastContainer, toast } from "react-toastify";
import PrintModal from "./printModal";
import AuthModal from "./AuthModal";
import AuthModalPermit from "./AuthModalPermit";
import Mtn from "./networkPrint.js/mtn";
import Airtel from "./networkPrint.js/airtel";

class VouchersHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      walletBalance: cookie.load("walletBalance"),
      token: cookie.load("token"),
      businessName: cookie.load("businessName"),
      vouchers: [],
      backup: [],
      readyPrint: false,
      readyVouchers: [],
      Pin: "",
      id: "",
      user: {},
      permitUser: "",
      selectedFilePin: "",
    };

    this.getVouchers = this.getVouchers.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.setPin = this.setPin.bind(this);
    this.onReprint = this.onReprint.bind(this);
    this.setID = this.setID.bind(this);
    this.setPermitUser = this.setPermitUser.bind(this);
    this.permitUser = this.permitUser.bind(this);
    this.getProfile = this.getProfile.bind(this);
  }

  onSubmit(e, ide) {
    const id = toast.loading("Making Reprint Request...");
    axios
      .post(
        WEB_BASE_URL + "/api/v2/w2/re-print-request",
        {
          transactionPin: this.state.Pin,
          requestId: ide,
        },
        {
          headers: {
            authorization: this.state.token,
          },
        }
      )
      .then((res) => {
        if (res.data.status == 2000) {
          toast.dismiss(id);
          toast.success("Reprint Request Made");
          //this.props.navigate(0);
        } else {
          console.log(res, {
            transactionPin: this.state.Pin,
            requestId: ide,
          });
          toast.update(id, {
            render: "" + res.data.description,
            type: "error",
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        toast.update(id, {
          render: "" + err,
          type: "error",
          isLoading: false,
        });
        setTimeout(() => {
          toast.dismiss(id);
        }, 3000);
      });
  }

  onReprint(e, ide, description) {
    const id = toast.loading("Making Reprint");
    axios
      .post(
        WEB_BASE_URL + "/api/v2/w2/logical-re-printVoucher",
        {
          transactionPin: this.state.Pin,
          requestId: ide,
        },
        {
          timeout: 1800000,
          maxContentLength: 1000000000,
          maxBodyLength: 1000000000,
          headers: {
            authorization: this.state.token,
          },
        }
      )
      .then((res) => {
        if (res.data.status == 2000) {
          this.setState({
            readyPrint: true,
            readyVouchers: res.data.data,
          });
          // console.log(res.data.data);
          toast.update(id, {
            render: "Preparing Document...",
            type: "warning",
            isLoading: true,
          });
          setTimeout(() => {
            this.performPrint(id, window.location, description).then((res) => {
              this.setState({
                readyPrint: false,
                readyVouchers: [],
              });
              setTimeout(() => {
                this.props.navigate("/vouchers-h");
                this.props.navigate(0);
              }, 500);
            });
          }, 400);
        } else {
          console.log(res);
          toast.update(id, {
            render: "" + res.data.description,
            type: "error",
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        toast.update(id, {
          render: "" + err,
          type: "error",
          isLoading: false,
        });
        console.log(err);
        setTimeout(() => {
          toast.dismiss(id);
        }, 3000);
      });
  }

  getProfile() {
    //toast loading

    axios
      .get(UNIFIED_BASE_URL + "/onboarding/get-user", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        console.log(res.data.data)
        this.setState({
          user: res.data.data[0],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  performPrint = async (id, location, description) => {
    if (this.state.readyVouchers[0].network == "MTN") {
      let print = new Mtn(
        id,
        this.state.selectedFilePin,
        description,
        this.state.readyVouchers,
        this.state.user.businessName
      );
      print.Print();
    } else if (this.state.readyVouchers[0].network == "AIRTEL") {
      let print = new Airtel(
        id,
        this.state.selectedFilePin,
        description,
        this.state.readyVouchers,
        this.state.user.businessName
      );
      print.Print();
    } else if (
      this.state.readyVouchers[0].network == "NINEMOBILE" ||
      this.state.readyVouchers[0].network == "GLO"
    ) {
      let print = new Airtel(
        id,
        this.state.selectedFilePin,
        description,
        this.state.readyVouchers,
        this.state.user.businessName
      );
      print.Print();
    }

  };

  getVouchers = () => {
    const id = toast.loading("Please wait...");
    axios
      .get(WEB_BASE_URL + "/api/v2/w2/get-voucher", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        if (res.data.data === null) {
          toast.update(id, {
            render: "No Vouchers Available",
            type: "error",
            isLoading: false,
          });
        } else {
          console.log(res.data);
          res.data.data.reverse();
          const filteredData = res.data.data.filter(
            (item) => item.orderStatus === "executed"
          );
          filteredData.forEach((element) => {
            element.ifMasked = true;
          });
          this.setState({ vouchers: filteredData, backup: filteredData });
          toast.dismiss(id);
        }
      })
      .catch((err) => {
        toast.update(id, {
          render: "" + err,
          type: "error",
          isLoading: false,
        });
        console.log(err);
        toast.dismiss(id);
      });
  };

  permitUser = (e, ide) => {
    const id = toast.loading("Making Reprint");
    axios
      .post(
        WEB_BASE_URL + "/api/v2/w2/print-request",
        {
          transactionPin: this.state.Pin,
          requestId: ide,
          reciepientEmail: this.state.permitUser,
        },
        {
          timeout: 10600000,
          maxContentLength: 100000000,
          maxBodyLength: 1000000000,
          headers: {
            authorization: this.state.token,
          },
        }
      )
      .then((res) => {
        if (res.data.status == 2000) {
          toast.dismiss(id);
          toast.success("User has been authorized to print");
          this.props.navigate(0);
        } else {
          console.log(res, {
            transactionPin: this.state.Pin,
            requestId: ide,
          });
          toast.update(id, {
            render: "" + res.data.description,
            type: "error",
            isLoading: false,
          });
        }
      })
      .catch((err) => { });
  };

  componentDidMount() {
    this.getVouchers();
    this.getProfile();
  }

  setPermitUser(event) {
    this.setState({
      permitUser: event.target.value,
    });
  }

  setPin(event) {
    this.setState({
      Pin: event.target.value,
    });
  }

  MaskedPin(ifMasked, pin) {
    let maskedPin = pin;
    if (ifMasked && pin !== null) {
      maskedPin = "";
      for (let i = 0; i < pin.length; i++) {
        maskedPin += "*";
      }
    }
    return <div>{maskedPin}</div>;
  }

  setID(id) {
    this.setState({
      id: id,
    });
  }

  render() {
    const printDiv = {
      display: this.state.readyPrint ? "block" : "none",
      minWidth: "9.25in",
      maxWidth: "8.25in",
      overflow: "auto",
    };
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
    });
    return (
      <main className="page-content">
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="javascript:;">
                    <i className="bx bx-home-alt"></i>
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  View Voucher History
                </li>
              </ol>
            </nav>
          </div>
          <div className="ms-auto">
            <div className="btn-group">
              <button type="button" className="btn btn-primary">
                Wallet Balance:{formatter.format(this.state.walletBalance)}
              </button>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header py-3">
            <div class="row g-3">
              <div class="col-lg-3 col-md-6 me-auto">
                <div class="ms-auto position-relative">
                  <div class="position-absolute top-50 translate-middle-y search-icon px-3">
                    <i class="bi bi-search"></i>
                  </div>
                  <input
                    class="form-control ps-5"
                    type="text"
                    placeholder="Search Batch"
                    onChange={(e) => {
                      let search = e.target.value;
                      if (search.length === 0) {
                        this.setState({
                          vouchers: this.state.backup,
                        });
                        return;
                      }
                      let vouchers = [].concat(this.state.vouchers);

                      let filteed = vouchers.filter((voucher) => {
                        return voucher.network
                          .toLowerCase()
                          .includes(search.toLowerCase());
                      });

                      this.setState({
                        vouchers: filteed,
                      });
                    }}
                  />
                </div>
              </div>

              <div class="col-lg-2 col-6 col-md-3">
                <select class="form-select">
                  <option>Show 10</option>
                  <option>Show 30</option>
                  <option>Show 50</option>
                </select>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table align-middle mb-0">
                <thead class="table-light">
                  <tr>
                    <th>Batch ID</th>
                    <th>Request ID</th>
                    <th>Description</th>
                    <th>Units</th>
                    <th>Voucher denomination</th>
                    <th>Network</th>
                    <th>File Pin</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.vouchers.map((voucher, index) => (
                    <tr>
                      <td>{voucher.id}</td>
                      <td>{voucher?.reqDescripionCode}</td>
                      <td>
                        <div class="d-flex align-items-center gap-3 cursor-pointer">
                          <div class="">
                            <p class="mb-0">{voucher.orderDescription}</p>
                          </div>
                        </div>
                      </td>
                      <td>{voucher.units}</td>
                      <td>&#8358;{voucher.unitAmount}</td>
                      <td>{voucher.network}</td>
                      <td
                        onClick={(e) => {
                          let text = "Are you sure you want to view this pin?";
                          if (window.confirm(text) === true) {
                            let vouchers = this.state.vouchers;

                            vouchers[index].ifMasked = false;
                            this.setState({
                              vouchers: vouchers,
                            });
                          }
                        }}
                      >
                        {this.MaskedPin(
                          voucher.ifMasked,
                          voucher.transactionPin
                        )}
                      </td>
                      <td>
                        <div class="d-flex align-items-center gap-2 fs-6">
                          <a
                            href="javascript:void(0);"
                            class="text-success mx-2"
                            title="Make Reprint Request"
                            data-bs-original-title="Reprint"
                            aria-label="Reprint"
                            data-bs-toggle="modal"
                            data-bs-target={"#AuthModal" + voucher.requestId}
                          >
                            <i class="bi  bi-arrow-repeat"></i>
                          </a>
                          <a
                            href="javascript:void(0);"
                            class="text-success"
                            title="Reprint"
                            data-bs-original-title="Reprint"
                            aria-label="Reprint"
                            data-bs-toggle="modal"
                            data-bs-target={
                              "#AuthModalReprint" + voucher.requestId
                            }
                            onClick={(e) => {
                              this.setState({
                                selectedFilePin: voucher.transactionPin,
                              });
                            }}
                          >
                            <i class="bi-cloud-arrow-down-fill"></i>
                          </a>
                          <a
                            href="javascript:void(0);"
                            class="text-success mx-2"
                            title="Permit User to Print"
                            data-bs-original-title="Permit User to Print"
                            aria-label="Permit User to Print"
                            data-bs-toggle="modal"
                            data-bs-target={
                              "#AuthModalPermit" + voucher.requestId
                            }
                          >
                            <i class="bi bi-person-plus-fill"></i>
                          </a>
                        </div>
                      </td>
                      <AuthModal
                        title={"Enter Authenticator Code for"}
                        id={"AuthModal" + voucher.requestId}
                        onSubmit={this.onSubmit}
                        formPin={this.state.Pin}
                        requestId={voucher.requestId}
                        setPin={this.setPin}
                      ></AuthModal>
                      <AuthModal
                        title={"Enter Authenticator Code"}
                        id={"AuthModalReprint" + voucher.requestId}
                        description={voucher.orderDescription}
                        onSubmit={this.onReprint}
                        requestId={voucher.requestId}
                        formPin={this.state.Pin}
                        setPin={this.setPin}
                      ></AuthModal>
                      <AuthModalPermit
                        title={"Permit User to Print"}
                        id={"AuthModalPermit" + voucher.requestId}
                        onSubmit={this.permitUser}
                        requestId={voucher.requestId}
                        permitUser={this.state.permitUser}
                        setPermitUser={this.setPermitUser}
                        formPin={this.state.Pin}
                        setPin={this.setPin}
                      ></AuthModalPermit>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <nav class="float-end mt-3">
              <ul class="pagination">
                <li class="page-item disabled">
                  <a class="page-link" href="#">
                    Previous
                  </a>
                </li>
                <li class="page-item active">
                  <a class="page-link" href="#">
                    1
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    2
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    3
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    Next
                  </a>
                </li>
              </ul>
            </nav>
          </div>

        </div>
      </main>
    );
  }
}

export default VouchersHistory;
